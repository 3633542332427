export enum ProductionSystemSwine {
  FarrowToFinish = "FarrowToFinish",
  SowsAndPiglets = "SowsAndPiglets",
  WeanToFinish = "WeanToFinish",
  Finishing = "Finishing",
  Nursery = "Nursery",
  Other = "Other"
}

export enum ProductionSystemPoultry {
  Standart = "Standart",
  SlowGrowing = "SlowGrowing",
  FreeRange = "FreeRange",
  Organic = "Organic",
  Other = "Other"
}
