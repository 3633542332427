import * as yup from "yup";
import i18next from "i18next";
import { YesNo } from "../enums/yesNo";
import { ContactType } from "../enums/contactType.enum";
import { PurposeOfAnalysis } from "../enums/purposeOfAnalysis.enum";

export interface LocationStepForm {
  farmId: string;
  useLocationAddress: string;
  contactType?: string | null;
  contactPerson?: string | null;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  samplingPlanned: string;
  // purposeOfAnalysis: string;
}

export const getLocationStepFormSchema = () => {
  return yup.object({
    customerId: yup.string().required().label(i18next.t("sample-request.customer")),
    farmId: yup.string().required().label(i18next.t("sample-request.farm")),
    useLocationAddress: yup
      .mixed<YesNo>()
      .oneOf(Object.values(YesNo))
      .required()
      .label(i18next.t("order-new-kit.location-step.use-location-address")),
    contactType: yup
      .mixed<ContactType>()
      .oneOf(Object.values(ContactType))
      .when("useLocationAddress", {
        is: YesNo.No,
        then: (schema) => schema.required().label(i18next.t("farm.contact-type"))
      }),
    contactPerson: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("farm.contact-person"))
    }),
    //Cannot use schema because it is conditional
    street: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.street"))
    }),
    //Cannot use schema because it is conditional
    number: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.number")),
      otherwise: (schema) => schema.notRequired().label(i18next.t("general.address.number")).label(i18next.t("general.address.number")) //Do not remove otherwise, this causes a validation bug
    }),
    //Cannot use schema because it is conditional
    postalCode: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.postal-code"))
    }),
    //Cannot use schema because it is conditional
    city: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.city"))
    }),
    //Cannot use schema because it is conditional
    country: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.country")),
      otherwise: (schema) => schema.notRequired().label(i18next.t("general.address.country"))
    }),
    //Cannot use schema because it is conditional
    state: yup.string().when("useLocationAddress", {
      is: YesNo.No,
      then: (schema) => schema.required().label(i18next.t("general.address.state"))
    }),
    samplingPlanned: yup
      .mixed<YesNo>()
      .oneOf(Object.values(YesNo))
      .required()
      .label(i18next.t("order-new-kit.location-step.sampling-planned")),
    // purposeOfAnalysis: yup
      // .mixed<PurposeOfAnalysis>()
      // .required()
      // .label(i18next.t("general.purpose-of-analysis.label")),
  });
};
