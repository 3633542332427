import { DsmTableCustomEvent, DsmTableData } from "@dsm-dcs/design-system";
import { DsmButton, DsmEmptyState, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/pageHeader/PageHeader";
import { AuthContext } from "../../contexts/auth.context";
import { useLayout } from "../../contexts/layout.context";
import { BaseLocation } from "../../models/API";
import { Role } from "../../models/enums/role.enum";
import { routeTypes, routes } from "../../routes";
import { getLocationsForCustomer } from "../../services/location.service";
import styles from "./CustomerDetails.module.scss";

function CustomerDetails() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);

  //State
  const pageSize = 10;
  const [isLoadingFarms, setIsLoadingFarms] = useState<boolean>(false);

  const [locations, setLocations] = useState<BaseLocation[]>([]);
  const [farmsTable, setFarmsTable] = useState<DsmTableData>();
  const [farmsCurrentPage, setFarmsCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  useEffect(() => {
      getLocations();
      setPageTitle(customer?.name ?? "...");
      setCrumbs([
        { title: t("customers.page.title"), url: routes.customers, type: routeTypes.customers },
        { title: customer?.name ?? "", url: routes.customerDetails.replace(":customerId", customer?.id ?? ""), type: routeTypes.customerSelected }
      ]);
  }, []);

  useEffect(() => {
    handleFarmsTableData(locations);
  }, [farmsCurrentPage]);

  //important
  const getLocations = async () => {
    setIsLoadingFarms(true);
    setFarmsCurrentPage(1);
    const locations = await getLocationsForCustomer(customer?.id || "", setToast);
    handleFarmsTableData(locations);
    setLocations(locations);
    setIsLoadingFarms(false);
    setTotalItems(locations.length);
  };

  const handleFarmsTableData = (tableLocations: BaseLocation[]) => {
    const startIndex = (farmsCurrentPage - 1) * pageSize;
    setFarmsTable({
      columns: [
        { id: "name", label: t("farm.name") },
        { id: "species", label: t("farm.species") },
        { id: "location", label: t("general.location") }
      ],
      data: tableLocations.slice(startIndex, startIndex + 10).map((_) => {
        return {
          isClickable: true,
          id: _.id || "",
          cells: [
            { headerText: _.name || "" },
            { value: _.groups && _.groups.length > 0 ? t(`general.species.${_.groups[0].toLowerCase()}`) : "" },
            { value: `${_.address?.city}, ${_.address?.country?.name}` }
          ]
        };
      })
    });
  };

  const handleFarmsTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSamples.replace(":locationId", e.detail));
  };

  const handleFarmsPageChange = (page: number) => {
    setFarmsCurrentPage(page);
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader
        header={customer?.name ? customer.name : t("customers.page.title")}
        totalItems={totalItems}
      ></PageHeader>
      {customer && (<div className={styles["custom-details__actions"]}>
        <DsmButton 
          variant="primary"
          onClick={() => navigate(routes.farmCreate)}
        >
          {t("farm-create.page.title")}
          <DsmIcon slot="before" name="general/plus-square"></DsmIcon>
        </DsmButton>
        {role === Role.Admin ? (
          <DsmButton 
            variant="primary"
            onClick={() => navigate(routes.order)}
          >
            {t("order-new-kit.page.title")}
            <DsmIcon slot="before" name="finance-ecommerce/shopping-cart-03"></DsmIcon>
          </DsmButton>
        ) : (
          ""
        )}
        <DsmButton 
          variant="primary"
          onClick={() => navigate(routes.sampleRequestCreate)}
        >
          {t("home.actions.register")}
          <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
        </DsmButton>
        {/* <DsmButton 
          variant="primary" 
          onClick={() => navigate(routes.sampleRequestPoultryCreate)}
        >
          {t("home.actions.register_poultry")}
          <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
        </DsmButton> */}
      </div>)}
        <div className={styles["table-details"]}>
          {!isLoadingFarms ? (
            <>
              {farmsTable && farmsTable.data.length > 0 ? (
                <>
                  <DsmTable data={farmsTable} onDsmRowClick={handleFarmsTableRowClick}></DsmTable>
                  {locations.length > pageSize ? (
                    <DsmPagination
                      currentPage={farmsCurrentPage}
                      pageCount={Math.ceil(locations.length / pageSize)}
                      onDsmChangePage={(e) => handleFarmsPageChange(e.detail)}
                    ></DsmPagination>
                  ) : null}
                </>
              ) : (
                <DsmEmptyState header={t("customers.empty.header")} icon="maps-travel/map-02"></DsmEmptyState>
              )}
            </>
          ) : (
            <DsmLoadingIndicator size="md"></DsmLoadingIndicator>
          )}
        </div>
    </DsmGrid>
  );
}

export default CustomerDetails;
