import {
  DsmLabelCloseEvent,
  DsmLabelCustomEvent,
  DsmRowActionClickEvent,
  DsmRowSelectedEvent,
  DsmSelectOption,
  DsmTableCustomEvent,
  DsmTableData
} from "@dsm-dcs/design-system";
import { DsmButton, DsmEmptyState, DsmGrid, DsmLabel, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Data } from "plotly.js";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../../components/disclaimer/Disclaimer";
import FilterContainer from "../../components/filter/filterContainer/filterContainer";
import SearchInput from "../../components/filter/searchInput/SearchInput";
import DateInput from "../../components/form/dateInput/DateInput";
import NumberInput from "../../components/form/numberInput/NumberInput";
import Select from "../../components/form/select/Select";
import PageHeader from "../../components/pageHeader/PageHeader";
import StatsTable from "../../components/statsTable/StatsTable";
import { DsmCDNUrl } from "../../config";
import { AuthContext } from "../../contexts/auth.context";
import { useLayout } from "../../contexts/layout.context";
import { getBoxPlotBackCss } from "../../helpers/sampleData.helper";
import useDetectPrint from "../../hooks/print.hook";
import { ComparisonType, FloatFilter, Page, SampleRequest, SortDirection, SpeciesGroup } from "../../models/API";
import { DashboardTableActions } from "../../models/enums/actions.enum";
import { Role } from "../../models/enums/role.enum";
import { getFilterDashboardSampleRequestFormSchema } from "../../models/forms/filter-dashboard-sample-request-form.model";
import { routes, routeTypes } from "../../routes";
import { getPhaseForSelect, getSpecies } from "../../services/metaData.service";
import { getSampleResult, getSampleResultsForCustomer } from "../../services/sample.service";
import { BoxPlotStatisticalData } from "../../types";
import styles from "./Dashboard.module.scss";

function Dashboard() {
  //Hooks
  const { t } = useTranslation();
  const isPrinting = useDetectPrint();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);
  const [schema] = useState(getFilterDashboardSampleRequestFormSchema());
  const {
    control: filterFormControl,
    watch: filterFormWatch,
    getValues: filterFormGetValues,
    reset: filterFormReset,
    setValue: filterFormSetValue
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });
  const groupWatch = filterFormWatch("groupId")

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [boxPlotStats, setBoxPlotStats] = useState<BoxPlotStatisticalData[]>([]);
  const [isRefreshingPlot, setIsRefreshingPlot] = useState<boolean>(true);
  const [isGettingAllResults, setIsGettingAllResults] = useState<boolean>(false);
  const [plotData, setPlotData] = useState<Data[]>([]);
  const [boxPlotBackCss, setBoxPlotBackCss] = useState<string>("");
  const [selectedResults, setSelectedResults] = useState<SampleRequest[]>([]);
  const [samplesTable, setSamplesTable] = useState<DsmTableData | null>(null);
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [phases, setPhases] = useState<DsmSelectOption[]>([]);
  const [resultFilterTypeOptions, setResultFilterTypeOptions] = useState<DsmSelectOption[]>([]);
  const filterFormSearchQuery = filterFormWatch("searchQuery");
  const [totalItems, setTotalItems] = useState<number>(0);
  const [species, setSpecies] = useState<DsmSelectOption[]>([]);
  const [subSpecies, setSubSpecies] = useState<DsmSelectOption[]>([]);

  //constants
  const itemsPerPage = 10;

  //Effects
  useEffect(() => {
    setPageTitle(t("dashboard.page.title"));
    setCrumbs([{ title: t("dashboard.page.title"), type: routeTypes.dashboard }]);
    initData();
    setBoxPlotBackCss(getBoxPlotBackCss());
    setSpecies([
      {value: SpeciesGroup.Swine, text: t("general.species.swine")}, 
      {value: SpeciesGroup.Poultry, text: t("general.species.poultry")}
    ]);

  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (currentSamplesTablePage !== 1) {
      setCurrentSamplesTablePage(1);
      return;
    }
    loadSamplesTable();
  }, [filterFormSearchQuery]);

  useEffect(() => {
    if (isLoading) return;
    loadSamplesTable();
  }, [currentSamplesTablePage]);

  useEffect(() => {
    const data: Data[] = [];
    const boxStats: BoxPlotStatisticalData[] = [];
    selectedResults.forEach((sampleResult, index) => {
      const yValues = sampleResult.samples?.map((sample) => sample.results?.[0]?.result ?? 0) ?? [];
      const hash = `#${index + 1}`
      const color = getRandomColor();
      data.push({
        name: hash,
        type: "box",
        boxpoints: "outliers",
        y: yValues,
        hoverinfo: "y",
        marker: { color },
      });
      boxStats.push(calculateStats(yValues, `${hash} - ${sampleResult.name}`, color));
    });
    setPlotData(data);
    setBoxPlotStats(boxStats);
    setIsRefreshingPlot(false);
  }, [selectedResults]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isPrinting]);

  useEffect(() => {
    if(!groupWatch) return;
    handlerSubSpeceis(filterFormGetValues("groupId"));
  },
  [groupWatch]
  )

  //Methods
  const initData = async () => {
    initFilterData();
    await loadSamplesTable(true);
    setIsLoading(false);
  };

  const handlerSubSpeceis = async (group: string | undefined) => {
    if (!group) return;
    filterFormSetValue("speciesId", undefined);
    filterFormSetValue("phaseIds", undefined);
    const subSpecies = await getSpecies(group as SpeciesGroup, setToast);
    const options = subSpecies.map((s) => ({
      value: s.id?.toString() || '',
      text: s.name?.toString() || ''
    }));

    if(group === SpeciesGroup.Swine) {
      filterFormSetValue("speciesId", subSpecies[0]?.id || undefined);
    }
    if(group === SpeciesGroup.Poultry) {
      filterFormSetValue("speciesId", options.find((_) => _.text.toLowerCase() === "broiler")?.value || undefined);
    }
    setSubSpecies(options ?? []);
  }

  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const loadSamplesTable = async (isInitial = false) => {
    let pageToken: string | null = null;
    if (currentSamplesTablePage) {
      pageToken = samplesTablePages.find((_) => _.page === currentSamplesTablePage - 1)?.token ?? null;
    }

    const filterValues = filterFormGetValues();
    const collectionDateStart = filterValues.collectionDateStart ? dayjs(filterValues.collectionDateStart) : undefined;
    const collectionDateEnd = filterValues.collectionDateEnd ? dayjs(filterValues.collectionDateEnd) : undefined;

    const sampleResults = await getSampleResultsForCustomer(
      role === Role.Customer ? customer?.id || "" : null,
      {
        itemsPerPage: itemsPerPage,
        sortDirection: SortDirection.Desc,
        pageToken,
        filters: {
          query: filterFormSearchQuery,
          collectionDate: {
            startDate: collectionDateStart && collectionDateStart.isValid() ? collectionDateStart.format("YYYY-MM-DD") : undefined,
            endDate: collectionDateEnd && collectionDateEnd.isValid() ? collectionDateEnd.format("YYYY-MM-DD") : undefined
          },
          phaseIds: filterValues.phaseIds ? [filterValues.phaseIds] : undefined,
          average: getAverageFilterValue(filterValues.resultsType, filterValues.resultsValue) ?? {
            type: ComparisonType.GT,
            value: 0.1
          },
          speciesId: filterValues.speciesId
        }
      },
      setToast
    );

    const samplesTableData: DsmTableData = {
      columns: [
        { id: "collectionDate", label: t("sample-request.collection-date") },
        { id: "name", label: t("sample-request.name") },
        { id: "farm", label: t("sample-request.farm") },
        { id: "phase", label: t("sample-request.phase") },
        { id: "average", label: t("sample-request.average") }
      ],
      data:
        sampleResults?.rows?.map((sampleResult, index) => {
          return {
            isSelectable: true,
            isSelected:
              isInitial && role === Role.Customer && index < 3
                ? true
                : selectedResults.some((result) => result.id === sampleResult.sampleRequestId),
            id: sampleResult.sampleRequestId || "",
            actions: [
              {
                type: DashboardTableActions.Results,
                icon: "charts/bar-chart-square-01",
                title: t("dashboard.samples-table.actions.results")
              }
            ],
            cells: [
              { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
              { value: sampleResult.name || "" },
              { value: sampleResult.locationName || "" },
              { value: sampleResult.phaseName || "" },
              {
                badges: [
                  {
                    value: sampleResult.average
                      ? `${sampleResult.average?.toString() || ""} ${t("general.unit-25ohd3")}`
                      : t("general.result-status.pending"),
                    variant: sampleResult.average ? "success" : "primary"
                  }
                ]
              }
            ]
          };
        }) || []
    };
    if (role === Role.Manager || role === Role.Admin) {
      samplesTableData.columns.splice(2, 0, { id: "customer", label: t("sample-request.customer") });
      sampleResults?.rows?.forEach((sampleResult, index) => {
        samplesTableData.data[index].cells.splice(2, 0, { value: sampleResult.customer?.name || "" });
      });
    }

    if (isInitial && role === Role.Customer) {
      const loadPromises: Promise<SampleRequest | null>[] = [];

      samplesTableData.data.forEach((row) => {
        if (row.isSelected) {
          loadPromises.push(getSampleResult(row.id, setToast));
        }
      });

      const loadedSelectedResults: SampleRequest[] = [];
      await Promise.all(loadPromises).then((results) => {
        results.forEach((sampleResult) => {
          if (sampleResult) {
            loadedSelectedResults.push(sampleResult);
          }
        });
      });
      setSelectedResults([...loadedSelectedResults]);
    }

    setSamplesTable(samplesTableData);
    setSamplesTablePages(sampleResults?.pages || []);
    setTotalItems(sampleResults?.total || 0);
  };

  const initFilterData = async () => {
    const species = await getSpecies(SpeciesGroup.Swine, setToast);
    const speciesId = species.find((_) => _.group === SpeciesGroup.Swine)?.id || "";
    setPhases(await getPhaseForSelect(speciesId, setToast));

    setResultFilterTypeOptions(
      Object.keys(ComparisonType).map((comparisonType) => {
        return { value: comparisonType, text: t(`general.comparison-type.${comparisonType.toLocaleLowerCase()}`) };
      })
    );
  };

  const handleTableRowSelected = async (e: DsmTableCustomEvent<DsmRowSelectedEvent>) => {
    setIsRefreshingPlot(true);
    const previousSelectedResult = selectedResults.find((result) => result.id === e.detail.id);
    if (previousSelectedResult) {
      setSelectedResults(selectedResults.filter((result) => result.id !== e.detail.id));
      return;
    }

    const sampleResult = await getSampleResult(e.detail.id, setToast);
    if (sampleResult) {
      setSelectedResults([...selectedResults, sampleResult]);
    }
  };

  const handleSelectAll = async () => {
    setSelectedResults([])
    setIsGettingAllResults(true);
    const results = await Promise.all((samplesTable?.data || []).map(s => getSampleResult(s.id, setToast))) as SampleRequest[];
     setSelectedResults(results)
    if(samplesTable){
      setSamplesTable({
        ...samplesTable,
        data: samplesTable.data.map((row) => ({ ...row, isSelected: true }))
      });
    }
    setIsGettingAllResults(false);
  }

  const handleLabelClose = (e: DsmLabelCustomEvent<DsmLabelCloseEvent>) => {
    setIsRefreshingPlot(true);
    const previousSelectedResult = selectedResults.find((result) => result.id === e.detail.identifier);
    if (previousSelectedResult) {
      setSelectedResults(selectedResults.filter((result) => result.id !== e.detail.identifier));
      if (samplesTable) {
        setSamplesTable({
          ...samplesTable,
          data: samplesTable.data.map((row) => (row.id === previousSelectedResult.id ? { ...row, isSelected: false } : row))
        });
      }
    }
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
  };

  const handleTableAction = (e: DsmTableCustomEvent<DsmRowActionClickEvent>) => {
    if (e.detail.action === DashboardTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  const handleApplyFilters = () => {
    loadSamplesTable();
  };

  const handleClearFilters = () => {
    filterFormReset();
  };

  const getAverageFilterValue = (resultsType: ComparisonType | undefined, resultsValue: number | undefined): FloatFilter | undefined => {
    if (resultsType && resultsValue) {
      return { type: resultsType, value: resultsValue };
    }

    return { type: ComparisonType.GT, value: 0.1 };
  };

  const handleClearAll = () => {
    setIsRefreshingPlot(true);
    setSelectedResults([]);
    if (samplesTable) {
      setSamplesTable({
        ...samplesTable,
        data: samplesTable.data.map((row) => ({ ...row, isSelected: false }))
      });
    }
  };

  const calculateStats = (
    values : number[], 
    name: string,
    color: string
  ): BoxPlotStatisticalData => {
    if (!values || values.length === 0) {
      throw new Error("Values array cannot be empty");
    }
  
    const sortedValues = [...values].sort((a, b) => a - b);
    const n = sortedValues.length;
  
    // Median
    const median =
      n % 2 === 0
        ? (sortedValues[n / 2 - 1] + sortedValues[n / 2]) / 2
        : sortedValues[Math.floor(n / 2)];
  
    // First Quartile (Q1)
    const lowerHalf = sortedValues.slice(0, Math.floor(n / 2));
    const q1 =
      lowerHalf.length % 2 === 0
        ? (lowerHalf[lowerHalf.length / 2 - 1] + lowerHalf[lowerHalf.length / 2]) / 2
        : lowerHalf[Math.floor(lowerHalf.length / 2)];
  
    // Third Quartile (Q3)
    const upperHalf = sortedValues.slice(Math.ceil(n / 2));
    const q3 =
      upperHalf.length % 2 === 0
        ? (upperHalf[upperHalf.length / 2 - 1] + upperHalf[upperHalf.length / 2]) / 2
        : upperHalf[Math.floor(upperHalf.length / 2)];
  
    // IQR and Fences
    const iqr = q3 - q1;
    const lowerFence = Math.max(q1 - 1.5 * iqr, sortedValues[0]);
    const upperFence = Math.min(q3 + 1.5 * iqr, sortedValues[n - 1]);
  
    // Min and Max
    const minimum = Math.min(...sortedValues);
    const maximum = Math.max(...sortedValues);

    return { 
      median, 
      firstQuartile: q1, 
      thirdQuartile: q3,  
      lowerFence, 
      upperFence, 
      maximum,
      minimum,
      name,
      color,
    };
  }

  return (
    <>
      <style>{boxPlotBackCss}</style>
      <style type="text/css">
        {"@media print{ @page { size: landscape; } }"}
      </style>
      <DsmGrid
        className={`main-container main-container--with-breadcrumb grid-refresh ${isRefreshingPlot || isGettingAllResults ? "grid-refresh__refreshing" : ""}`}
        fixed={true}
        container-only={true}
      >
        <PageHeader 
        header={isPrinting ? t("dashboard.report.title") : t("dashboard.page.title")} 
        totalItems={totalItems}
        description={t("dashboard.page.description")}
        actionsSide={
          !isLoading ? 
          <DsmButton 
            onClick={() => window.print()}
            disabled={boxPlotStats.length === 0}
          >
            {t("general.print-report")}
          </DsmButton> 
          : null
        }
        ></PageHeader>
        {!isLoading ? (
          <>
          {!isPrinting && (
            <>
            <div className="filters">
              <div className="search">
                <SearchInput fieldName="searchQuery" control={filterFormControl} placeholder={t("general.search")}></SearchInput>
              </div>
              <div className={styles["filters-buttons"]}>
              <DsmButton variant='secondary' onClick={handleSelectAll} disabled={selectedResults.length === itemsPerPage}>
                {t("general.select-all")}
              </DsmButton>
              <div>
              <FilterContainer onApply={handleApplyFilters} onClear={handleClearFilters}>
                <>
                  <div className="filter-row__two-items">
                    <DateInput
                      fieldName="collectionDateStart"
                      control={filterFormControl}
                      label={t("sample-request.collection-date-start")}
                    ></DateInput>
                    <DateInput
                      fieldName="collectionDateEnd"
                      control={filterFormControl}
                      label={t("sample-request.collection-date-end")}
                    ></DateInput>
                  </div>
                  {/* <Select fieldName="groupId" control={filterFormControl} options={species} label={t("sample-request.species")}/>
                  {filterFormGetValues("groupId") === SpeciesGroup.Poultry && (<Select fieldName="speciesId" control={filterFormControl} options={subSpecies} label={t("sample-request-create-poultry.sub-species")} />)} */}
                  {/* {filterFormGetValues("groupId") === SpeciesGroup.Swine &&( */}
                  <Select fieldName="phaseIds" control={filterFormControl} options={phases} label={t("sample-request.phase")}/>
                  {/* )} */}
                  <div className="filter-row__two-items">
                    <Select
                      fieldName="resultsType"
                      control={filterFormControl}
                      options={resultFilterTypeOptions}
                      label={t("sample-request.result-type")}
                    />
                    <NumberInput
                      fieldName="resultsValue"
                      control={filterFormControl}
                      label={t("sample-request.result-value")}
                    ></NumberInput>
                  </div>
                </>
              </FilterContainer>
              </div>
              </div>
          </div>
            <div className="card card--table">
              {samplesTable?.data.length ? (
                <>
                  <DsmTable
                    data={samplesTable}
                    onDsmRowSelected={handleTableRowSelected}
                    onDsmRowActionClick={handleTableAction}
                  ></DsmTable>
                  {samplesTablePages.length > 0 ? (
                    <DsmPagination
                      currentPage={currentSamplesTablePage}
                      pageCount={samplesTablePages.length}
                      onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                    ></DsmPagination>
                  ) : null}
                </>
              ) : (
                <DsmEmptyState icon="charts/pie-chart-01" header={t("dashboard.samples-table.empty")}></DsmEmptyState>
              )}
            </div>
            </>)}
           <div className={styles["selected-samples-header"]}>
              <h3>{t("dashboard.selected-header")}</h3>
              {selectedResults.length > 0 && !isPrinting ? (
                <DsmButton variant="text" onClick={handleClearAll}>
                  {t("dashboard.clear-all")}
                </DsmButton>
              ) : null}
            </div>
            <div className={styles["selected-samples"]}>
              {selectedResults.map((sampleResult, index) => (
                <DsmLabel key={sampleResult.id} identifier={sampleResult.id} closeable={true} onDsmClosed={handleLabelClose}>
                  #{index + 1} {sampleResult.name}
                </DsmLabel>
              ))}
              {selectedResults.length === 0 ? <span>{t("dashboard.no-selected")}</span> : null}
            </div>
            <div className={`panel ${styles["graph-panel"]}`}>
              {!isRefreshingPlot && selectedResults.length === 0 ? (
                <div className={styles["box-plot__empty-text"]}>{t("dashboard.select-results")}</div>
              ) : null}
              <Plot
                className={`box-plot ${styles["box-plot"]} ${
                  !isRefreshingPlot && selectedResults.length === 0 ? styles["box-plot__empty"] : ""
                }`}
                data={plotData}
                layout={{
                  width: isPrinting ? 1100 : undefined,
                  height: isPrinting ? 500 : undefined,
                  dragmode: false,
                  yaxis: {
                    range: [0, 101],
                    dtick: 10,
                    title: t("dashboard.boxplot.yaxis")
                  },
                  xaxis: { type: "category" },
                  margin: { t: 40, b: 40, r: 40, l: 70 },
                  showlegend: false,
                  hovermode: false,
                  hoverlabel: {
                    font: { family: '"DM Sans", sans-serif' }
                  },
                  font: {
                    family: '"DM Sans", sans-serif'
                  }
                }}
                config={{ staticPlot: false, responsive: true, displayModeBar: false, doubleClick: false, scrollZoom: false }}
                useResizeHandler={true}
                style={{ width: "100%", height: "530px" }}
              />
              <DsmLoadingIndicator size="lg" className="grid-refresh__loader"></DsmLoadingIndicator>
            </div>
            {boxPlotStats.length > 0 && <div className={styles["stats"]}>
              <StatsTable data={boxPlotStats}/>
            </div>}
            <Disclaimer
              isPrinting={isPrinting}
            />
          <div className={styles["print-footer"]}>
            <div className={styles["print-footer__logo"]}>
              <span>www.dsm-firmenich.com/anh</span>
              <span className={styles["print-footer__page-number"]}></span>
              <img src={`${DsmCDNUrl}/images/logo.svg`} alt="logo"></img>
            </div>
          </div>
          </>
        ) : (
          <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
        )}
      </DsmGrid>
    </>
  );
}

export default Dashboard;
