import { DsmSelectOption } from "@dsm-dcs/design-system";
import { DsmLoadingIndicator, DsmButton, DsmIcon, DsmAlert } from "@dsm-dcs/design-system-react";
import { Dispatch, SetStateAction, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { YesNo } from "../../../../../models/enums/yesNo";
import { ContactType } from "../../../../../models/enums/contactType.enum";
import { PurposeOfAnalysis } from "../../../../../models/enums/purposeOfAnalysis.enum";
import { LocationStepForm } from "../../../../../models/forms/location-step-form.model";
import { getLocationStepFormSchema } from "../../../../../models/forms/location-step-form.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { getCountriesForSelect } from "../../../../../services/metaData.service";
import styles from "./locationStep.module.scss";
import { getCustomersForSelect, getUser } from "../../../../../services/user.service";
import { getLocation, getLocationsForCustomer } from "../../../../../services/location.service";
import { routes } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { Location } from "../../../../../models/API";
import { useLayout } from "../../../../../contexts/layout.context";
import Select from "../../../../../components/form/select/Select";
import Input from "../../../../../components/form/input/Input";
import RadioButtonGroup from "../../../../../components/form/radioButtonGroup/RadioButtonGroup";
import { AuthContext } from "../../../../../contexts/auth.context";
import { Role } from "../../../../../models/enums/role.enum";

export type LocationStepRef = {
  getFormData: () => LocationStepForm;
  getAddress: () => string[];
};

type Props = {
  setFormStepValid: Dispatch<SetStateAction<boolean>>;
};

const LocationStep = forwardRef<LocationStepRef, Props>(function LocationStep({ setFormStepValid }, ref) {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);
  const [schema] = useState(getLocationStepFormSchema());
  const {
    control,
    watch,
    setValue,
    getValues,
    resetField,
    formState: { errors, isValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      contactType: ContactType.Me
    }
  });

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingFarms, setIsLoadingFarms] = useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = useState<DsmSelectOption[]>([]);
  const [farmOptions, setFarmOptions] = useState<DsmSelectOption[]>([]);
  const [location, setLocation] = useState<Location | null>(null);
  const [contactName, setContactName] = useState<string>("");
  const [countryOptions, setCountryOptions] = useState<DsmSelectOption[]>([]);
  const customerId = watch("customerId");
  const contactType = watch("contactType");
  const useLocationAddress = watch("useLocationAddress");
  const samplingPlanned = watch("samplingPlanned");
  const farmId = watch("farmId");
  const [yesNoOptions, setYesNoOptions] = useState<DsmSelectOption[]>([]);
  const [contactTypeOptions, setContactTypeOptions] = useState<DsmSelectOption[]>([]);
  const [purposeOfAnalysisOptions, setPurposeOfAnalysisOptions] = useState<DsmSelectOption[]>([]);

  //Exposed methods
  useImperativeHandle(
    ref,
    () => {
      return {
        getFormData: (): LocationStepForm => {
          return getValues();
        },
        getAddress: (): string[] => {
          const formData = getValues();
          if (useLocationAddress === YesNo.No) {
            return [
              formData.contactPerson || "",
              `${formData.street} ${formData.number?.toString() || ""}`,
              `${formData.postalCode} ${formData.city}`,
              `${formData.state} ${countryOptions.find((_) => _.value === formData.country)?.text || ""}`
            ];
          } else if (useLocationAddress === YesNo.Yes && location) {
            return [
              location.contact?.name || "",
              `${location.address?.street || ""} ${location.address?.number?.toString() || ""}`,
              `${location.address?.postalCode || ""} ${location.address?.city || ""}`,
              `${location.address?.state || ""} ${location.address?.country?.name || ""}`
            ];
          }
          return [];
        }
      };
    },
    [useLocationAddress, location]
  );

  //Effects
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (contactType === ContactType.Me) {
      setValue("contactPerson", contactName);
    } else {
      setValue("contactPerson", "");
    }
  }, [contactType]);

  useEffect(() => {
    handleCustomerChange();
  }, [customerId]);

  useEffect(() => {
    handleLocationChange();
  }, [farmId]);

  useEffect(() => {
    setFormStepValid(isValid && !!location);
  }, [isValid, location]);

  useEffect(() => {
    getFarms();
  }, [customerId]);

  //Methods
  const initData = async (): Promise<void> => {
    setYesNoOptions(
      Object.keys(YesNo).map((option) => {
        return { value: option, text: t(`general.yes-no.${option.toLocaleLowerCase()}`) };
      })
    );
    setContactTypeOptions(
      Object.keys(ContactType).map((contactType) => {
        return { value: contactType, text: t(`general.contact-type.${contactType.toLocaleLowerCase()}`) };
      })
    );
    // setPurposeOfAnalysisOptions(
    //   Object.keys(PurposeOfAnalysis).map((purposeOfAnalysisOptions) => {
    //     return { value: purposeOfAnalysisOptions, text: t(`general.purpose-of-analysis.options.${purposeOfAnalysisOptions?.toLowerCase()}`) };
    //   })
    // );
    await Promise.all([initCustomers(), initUser(), initCountries()]);
    setIsLoading(false);
  };

  const initCustomers = async (): Promise<void> => {
    if (role !== Role.Manager && role !== Role.Admin) {
      setValue("customerId", customer?.id ?? "");
      return;
    }

    const customerOptions = await getCustomersForSelect(setToast);
    setCustomerOptions(customerOptions);
    if (customer?.id) {
      setValue("customerId", customer?.id ?? "");
    }
  };

  const initUser = async (): Promise<void> => {
    const user = await getUser();
    setValue("contactPerson", user.fullName);
    setContactName(user.fullName);
  };

  const initCountries = async (): Promise<void> => {
    const countryOptions = await getCountriesForSelect(setToast);
    setCountryOptions(countryOptions);
  };

  const getFarms = async (): Promise<void> => {
    if (!customerId) {
      setFarmOptions([]);
      return;
    }

    setIsLoadingFarms(true);

    const locations = await getLocationsForCustomer(customerId || "", setToast);
    const farmOptions: DsmSelectOption[] = locations.map((location) => {
      return {
        text: location.name || "",
        value: location.id || ""
      };
    });

    farmOptions.sort((a, b) => a.text.localeCompare(b.text));
    setFarmOptions(farmOptions);
    setIsLoadingFarms(false);
  };

  const handleCustomerChange = async (): Promise<void> => {
    resetField("farmId");
    await getFarms();
  };

  const handleLocationChange = async (): Promise<void> => {
    if (farmId) {
      setLocation(null);
      setLocation(await getLocation(farmId, setToast));
    }
  };

  const handleAddFarm = (): void => {
    navigate(routes.farmCreate);
  };

  return (
    <>
      {!isLoading ? (
        <div className={`${styles['form-order-kit']}`}>
            <div className={styles['form-order-kit__part']}>
              {role === Role.Manager || role === Role.Admin ? (
                <Select
                  fieldName="customerId"
                  control={control}
                  options={customerOptions}
                  label={t("order-new-kit.location-step.select-customer")}
                  fieldError={errors.customerId}
                  schema={schema}
                ></Select>
              ) : null}
              <div>
                <Select
                  fieldName="farmId"
                  control={control}
                  options={farmOptions}
                  label={t("order-new-kit.location-step.select-location")}
                  fieldError={errors.farmId}
                  schema={schema}
                  disabled={!customerId}
                  loading={isLoadingFarms}
                  emptyText={t("general.no-options")}
                ></Select>
                <DsmButton className={styles["create-farm"]} variant="text" onClick={handleAddFarm}>
                  {t("order-new-kit.location-step.create-farm")}
                  <DsmIcon slot="before" name="general/plus"></DsmIcon>
                </DsmButton>
              </div>
              {farmId ? (
                <>
                  <RadioButtonGroup
                    control={control}
                    fieldName="useLocationAddress"
                    label={t("order-new-kit.location-step.use-location-address")}
                    options={yesNoOptions}
                    fieldError={errors.useLocationAddress}
                    schema={schema}
                  ></RadioButtonGroup>
                  {useLocationAddress === YesNo.No ? (
                    <>
                      <Input
                        control={control}
                        fieldName="contactPerson"
                        label={t("farm.contact-person")}
                        fieldError={errors.contactPerson}
                        disabled={contactType === ContactType.Me}
                        schema={schema}
                      ></Input>
                      <RadioButtonGroup
                        control={control}
                        fieldName="contactType"
                        options={contactTypeOptions}
                        fieldError={errors.contactType}
                        schema={schema}
                      ></RadioButtonGroup>
                      <div className={styles.form__row}>
                        <Input
                          control={control}
                          fieldName="street"
                          label={t("general.address.street")}
                          fieldError={errors.street}
                          forceRequired={true} //Cannot use schema because it is conditional
                        ></Input>
                        <Input
                          control={control}
                          fieldName="number"
                          label={t("general.address.number")}
                          fieldError={errors.number}
                          forceRequired={true} //Cannot use schema because it is conditional
                        ></Input>
                      </div>
                      <div className={styles.form__row}>
                        <Input
                          control={control}
                          fieldName="postalCode"
                          label={t("general.address.postal-code")}
                          fieldError={errors.postalCode}
                          forceRequired={true} //Cannot use schema because it is conditional
                        ></Input>
                        <Input
                          control={control}
                          fieldName="city"
                          label={t("general.address.city")}
                          fieldError={errors.city}
                          forceRequired={true} //Cannot use schema because it is conditional
                        ></Input>
                      </div>
                      <div className={styles.form__row}>
                        <Input
                          control={control}
                          fieldName="state"
                          label={t("general.address.state")}
                          fieldError={errors.state}
                          forceRequired={true} //Cannot use schema because it is conditional
                        ></Input>
                        <Select
                          fieldName="country"
                          control={control}
                          options={countryOptions}
                          label={t("general.address.country")}
                          fieldError={errors.country}
                          forceRequired={true} //Cannot use schema because it is conditional
                          useSearch={true}
                          emptyText={t("general.no-options")}
                        ></Select>
                      </div>
                    </>
                  ) : null}
                  {useLocationAddress === YesNo.Yes ? (
                    <>
                      {location ? (
                        <div>
                          <div>{location?.contact?.name}</div>
                          <div>
                            {location?.address?.street} {location?.address?.number}
                          </div>
                          <div>
                            {location?.address?.postalCode} {location?.address?.city}
                          </div>
                          <div>{location?.address?.country?.name}</div>
                        </div>
                      ) : (
                        <DsmLoadingIndicator size="sm"></DsmLoadingIndicator>
                      )}
                    </>
                  ) : null}
                  <RadioButtonGroup
                    control={control}
                    fieldName="samplingPlanned"
                    label={t("order-new-kit.location-step.sampling-planned")}
                    options={yesNoOptions}
                    fieldError={errors.samplingPlanned}
                    schema={schema}
                  ></RadioButtonGroup>
                  {samplingPlanned === YesNo.No ? (
                    <DsmAlert variant="warning" closeable={false}>
                      {t("order-new-kit.location-step.sampling-planned-no-warning")}
                    </DsmAlert>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={styles['form-order-kit__part']}>
              {/* <Select
                fieldName="purposeOfAnalysis"
                control={control}
                options={purposeOfAnalysisOptions}
                label={t("general.purpose-of-analysis.label")}
                fieldError={errors.purposeOfAnalysis}
                schema={schema}
                forceRequired={true}
              ></Select> */}
            </div>
          </div>
      ) : (
        <DsmLoadingIndicator className={styles["loader"]} size="md"></DsmLoadingIndicator>
      )}
    </>
  );
});

export default LocationStep;
