import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { DsmButton, DsmEmptyState, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmTable } from "@dsm-dcs/design-system-react";
import { DsmTableData } from "@dsm-dcs/design-system/dist/types/types/table";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader/PageHeader";
import UserGuide from "../../../components/userGuide/UserGuide";
import { AuthContext } from "../../../contexts/auth.context";

import { useLayout } from "../../../contexts/layout.context";
import { getKitOrderLabel, getVariantForStatus } from "../../../helpers/orderKit.helper";
import { KitRequestTableInput, SampleResultTableInput, SampleResultTableItem, SortDirection } from "../../../models/API";
import { Role } from "../../../models/enums/role.enum";
import { routeTypes, routes } from "../../../routes";
import { getKitRequests } from "../../../services/kitRequest.service";
import { getAdminExportUrl } from "../../../services/metaData.service";
import { getSampleResultsForCustomer } from "../../../services/sample.service";
import styles from "./Home.module.scss";

function Home() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);

  //State
  const [sampleResults, setSampleResults] = useState<SampleResultTableItem[]>([]);
  const [resultsTable, setResultsTable] = useState<DsmTableData | null>(null);
  const [ordersTable, setOrdersTable] = useState<DsmTableData | null>(null);

  //Effects
  useEffect(() => {
    setPageTitle(t("home.page.title"));
    setCrumbs([{ title: "", type: routeTypes.home }]);
    initData();
  }, []);

  //Methods
  const initData = async () => {
    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 4,
      sortDirection: SortDirection.Desc,
      excludePendingResults: true
    };
    const kitRequestTableInput: KitRequestTableInput = { itemsPerPage: 4, sortDirection: SortDirection.Desc };
    await Promise.all([
      getSampleResultsForCustomer(role === Role.Customer ? customer?.id || "" : null, sampleResultTableInput, setToast),
      getKitRequests(role === Role.Customer ? customer?.id || "" : null, kitRequestTableInput, setToast)
    ]).then(async (data) => {
      const sampleResults = data[0];
      const kitRequests = data[1];

      const resultTable: DsmTableData = {
        columns: [
          { id: "collectionDate", label: t("sample-request.collection-date") },
          { id: "name", label: t("sample-request.name") },
          { id: "farm", label: t("sample-request.farm") },
          { id: "phase", label: t("sample-request.phase") },
          { id: "average", label: t("sample-request.average") }
        ],
        data:
          sampleResults?.rows?.map((sampleResult) => {
            return {
              isClickable: true,
              id: sampleResult.sampleRequestId || "",
              cells: [
                { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
                { headerText: sampleResult.name || "" },
                { value: sampleResult.locationName || "" },
                { value: sampleResult.phaseName || "" },
                {
                  badges: [
                    {
                      value: sampleResult.average
                        ? `${sampleResult.average?.toString() || ""} ${t("general.unit-25ohd3")}`
                        : t("general.result-status.pending"),
                      variant: sampleResult.average ? "success" : "primary"
                    }
                  ]
                }
              ]
            };
          }) || []
      };
      if (role === Role.Manager || role === Role.Admin) {
        resultTable.columns.splice(2, 0, { id: "customer", label: t("sample-request.customer") });
        sampleResults?.rows?.forEach((sampleResult, index) => {
          resultTable.data[index].cells.splice(2, 0, { value: sampleResult.customer?.name || "" });
        });
      }
      setSampleResults(sampleResults?.rows || []);
      setResultsTable(resultTable);

      const ordersTable: DsmTableData = {
        columns: [
          { id: "order", label: t("order.order") },
          { id: "farm", label: t("order.farm") },
          { id: "date", label: t("order.date") },
          { id: "amount", label: t("order.kits") },
          { id: "status", label: t("order.status") }
        ],
        data:
          kitRequests?.rows?.map((kitRequest) => {
            return {
              id: kitRequest.kitRequestId || "",
              cells: [
                { headerText: kitRequest.orderNumber?.toString() || "" },
                { value: kitRequest.locationName || "" },
                { value: dayjs(kitRequest.createdDateTime || "").format(t("general.date-format")) },
                { value: kitRequest.totalAmount?.toString() || "" },
                {
                  badges: kitRequest.status
                    ? [
                        {
                          value: getKitOrderLabel(kitRequest.status),
                          variant: getVariantForStatus(kitRequest.status)
                        }
                      ]
                    : []
                }
              ]
            };
          }) || []
      };
      if (role === Role.Manager || role === Role.Admin) {
        ordersTable.columns.splice(1, 0, { id: "customer", label: t("sample-request.customer"), hideFrom: "md" });
        kitRequests?.rows?.forEach((kitRequest, index) => {
          ordersTable.data[index].cells.splice(1, 0, { value: kitRequest.customer?.name || "" });
        });
      } else {
        ordersTable.columns.push({ id: "tracking", label: "Tracking number" });
        kitRequests?.rows?.forEach((kitRequest, index) => {
          ordersTable.data[index].cells.push({ value: kitRequest.trackAndTrace || "" });
        });
      }
      setOrdersTable(ordersTable);
    });
  };

  const handleRegisterClick = () => {
    navigate(routes.sampleRequestCreate);
  };

  const handleRegisterPoultryClick = () => {
    navigate(routes.sampleRequestPoultryCreate);
  };

  const orderNewKit = () => {
    navigate(routes.order);
  };

  const exportAdminSampleData = async () => {

    const url = await getAdminExportUrl(setToast);
    window.open(url);
  };

  const handleSampleTableRowClick = async (e: DsmTableCustomEvent<string>) => {
    if (role === Role.Manager || role === Role.Admin) {
      const sampleResult = sampleResults.find((sampleResult) => sampleResult.sampleRequestId === e.detail);
      sessionStorage.setItem("customerId", sampleResult?.customer?.id ?? "");
    }
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  return (
    <>
      <div className={styles.hero}>
        <DsmGrid className={styles.hero__content} fixed={true} container-only={true}>
          <h1 className={styles.hero__header}>{t("home.hero.header")}</h1>
          <div className={styles.hero__description}>{t("home.hero.description")}</div>
        </DsmGrid>
      </div>
      <DsmGrid className="main-container" fixed={true} container-only={true}>
        <PageHeader
          header={t("home.header")}
        >
          <UserGuide />
          {role === Role.Admin ? (
            <DsmButton onClick={orderNewKit}>
              {t("home.actions.order")}
              <DsmIcon slot="before" name="finance-ecommerce/shopping-cart-03"></DsmIcon>
            </DsmButton>
          ) : (
            ""
          )}
          <DsmButton onClick={handleRegisterClick}>
            {t("home.actions.register")}
            <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
          </DsmButton>
          {false && (<DsmButton onClick={handleRegisterPoultryClick}>
            {t("home.actions.register_poultry")}
            <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
          </DsmButton>)}
          {role === Role.Admin ? (
          <DsmButton onClick={exportAdminSampleData}>
              {t("home.actions.export")}
              <DsmIcon slot="before" name="general/download-cloud-01"></DsmIcon>
          </DsmButton>
          ) : (
            ""
          )}
        </PageHeader>
        <div className={styles.tables}>
          <div className={styles.tables__table}>
            <div className={styles.tables__header}>
              <h3>{t("home.results.header")}</h3>
              <div>
                <Link to={routes.samples}>
                  {t("general.view-all")} <DsmIcon name="arrows/chevron-right"></DsmIcon>
                </Link>
              </div>
            </div>
            {resultsTable ? (
              resultsTable.data.length > 0 ? (
                <DsmTable data-testid="results-table" data={resultsTable} onDsmRowClick={handleSampleTableRowClick}></DsmTable>
              ) : (
                <DsmEmptyState
                  data-testid="results-empty-state"
                  icon="charts/pie-chart-01"
                  header={t("home.results.empty")}
                ></DsmEmptyState>
              )
            ) : (
              <DsmLoadingIndicator></DsmLoadingIndicator>
            )}
          </div>
          <div className={styles.tables__table}>
            <div className={styles.tables__header}>
              <h3>{t("home.orders.header")}</h3>
              <div>
                <Link to={routes.orderStatus}>
                  {t("general.view-all")} <DsmIcon name="arrows/chevron-right"></DsmIcon>
                </Link>
              </div>
            </div>
            {ordersTable ? (
              ordersTable.data.length ? (
                <DsmTable data-testid="orders-table" data={ordersTable}></DsmTable>
              ) : (
                <DsmEmptyState
                  data-testid="orders-empty-state"
                  icon="finance-ecommerce/shopping-cart-03"
                  header={t("home.orders.empty")}
                ></DsmEmptyState>
              )
            ) : (
              <DsmLoadingIndicator></DsmLoadingIndicator>
            )}
          </div>
        </div>
      </DsmGrid>
    </>
  );
}

export default Home;
